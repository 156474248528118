html {
  font-family: 'Oxygen', sans-serif;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: rgba(238, 238, 238, 0.87); /* $grey-extra-light */
  margin: 0;
  padding: 0;
  background: white;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

.virtualizedFormList {
  outline: 0;
  padding: 0 2rem 4rem;
}

@media screen and (max-width: 800px) {
  .virtualizedFormList {
    padding: 0 0 4rem 0;
  }
}

.virtualizedFormList > .ReactVirtualized__Grid__innerScrollContainer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px;
}
